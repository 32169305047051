import React from "react";
import {Badge, Col, Container, Row} from "react-bootstrap";
import "./research.css"

const Research = () => {
    const titleList = ['FLOW CYTOMETRY', 'MASS-SPEC', 'PLANT PHENOMICS', 'CELL COMMUNICATION']
    return (
        <div className="header_div">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="div_pa">
                            <h3>Research</h3>

                        </div>
                        <p>&nbsp;&nbsp;For centuries, the human being on earth are longing for two things: the infinite
                            and the finite aspect of the universe. The former gives us science, helping us better
                            understand about the nature; and the latter gives us technology, helping us get involved in
                            the nature. </p>
                    </Col>
                    <Col md={6} align="center"><img src={"/images/xian2.png"} className="img-thumbnail" alt="img"/></Col>
                    <Col md={6} align="center"><img src={"/images/timg1.png"} className="img-thumbnail" alt="img"/></Col>
                    <Col md={12}><p> &nbsp;&nbsp; In the menlab, we are standing at the intersection of science and
                        technology,and we are focusing on the subject of microfluidics, because we believe the future
                        for scientific researching and technology development lies in biology and its applications, and
                        microfluidics would be an essential tool. On one hand, we do some fundamental research on some
                        interesting physical, cellular, and molecular phenomena in microfluidic environment, trying to
                        understand better about the related physics, chemistry, and biology; and on the other hand, we
                        cling with biologists to push forward the applications about the microfluidic technologies, and
                        even work closely with the industry to turn the prototypes into actual productivity.</p></Col>
                    <Col md={12}>
                        <div className="div_pa"><h3>The menlab currently are using two major research tools</h3></div>
                    </Col>
                    <Col md={6}>
                        <Col md={12} align="center">
                            <img src={"/images/wei.png"} className="img-thumbnail" alt="img"/>
                        </Col>
                        <h4 className="h4_img">MICROFLUIDIC</h4>
                        <p>&nbsp;&nbsp;Microfluidics is defined as the device or technology that manipulates the fluids
                            in micro
                            level. In the menlab, this would be the center of everything. In fact, the term “the menlab”
                            stands for “the Microfluidic Engineering and Novelty lab”. We have a faith that
                            microfluidics is the best means to connect the answer to the question, the treatment to the
                            cure, and the salt to the light.</p>
                    </Col>
                    <Col md={6}>
                        <Col md={12} align="center">
                            <img src={"/images/xian.png"} className="img-thumbnail" alt="img"/>
                        </Col>

                        <h4 className="h4_img">MICROSCOPY</h4>
                        <p>&nbsp;&nbsp;One of the major methods to quantify and characterize the microfluidic phenomenon
                            is optical
                            microscopy. Aside from the traditional fluorescent and confocal planar imaging method, we
                            strive to develop prototypes suitable to get three dimensional images of samples in
                            microfluidic chips, such as light sheet microscopy, with or without the microscope
                            setup.</p>
                    </Col>
                    <Col md={12}>
                        <Col md={12} align="center">
                            <img src={"/images/xinpian.png"} className="img-thumbnail" alt="img"/>
                        </Col>
                        <h4 className="h4_img">Machine Learning</h4>
                        <p>&nbsp;&nbsp;As one of the unique and highly window opening studies, we attempt to apply the latest technologies in machine learning, especially machine vision, to the field of microfluidics. We try to improve the accuracy of fluid control in the field of microfluidics, or to create microfluidic engineering devices with visual recognition capabilities.</p>
                    </Col>
                    <Col md={12}>
                        <div className="div_pa">
                            <h3>the menlab currently are focucing on the following on-going projects</h3>
                        </div>
                    </Col>
                    {
                        titleList.map((item, index) => {
                            return <Col md={12} key={item}>
                                <Col>
                                    <h1>
                                        <Badge bg="secondary">{index + 1}</Badge>
                                        <span className="text_font">{item}</span>
                                    </h1>
                                </Col>
                            </Col>
                        })
                    }

                </Row>
            </Container>
        </div>
    )
}

export default Research