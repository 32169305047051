import {Button, Card, Col, Container, Row} from "react-bootstrap";
import React from "react";
import "./card.css"

const OtherData = () => {
    const cardData = [
        {
            img: "/images/wei.png",
            title: "MICROFLUIDICS",
            text: "Microfluidics is defined as the device or technology that manipulates the fluids in micro level. In the menlab, this would be the center of everything."
        },
        {
            img: "/images/xian.png",
            title: "MICROSCOPY",
            text: "Microfluidics is defined as the device or technology that manipulates the fluids in micro level. In the menlab, this would be the center of everything."
        },
        {
            img: "/images/test1.png",
            title: "FOCUSING ON",
            text: "we are focusing on the subject of microfluidics, because we believe the future for scientific researching and technology development lies in biology and its applications, and microfluidics would be an essential tool."
        },
    ]
    const careersData=[
        {
            img: "/images/working-here_0.jpg",
            title: "Working Here",
            text: "Leveraging the talents of world-class researchers"
        },
        {
            img: "/images/research-community_0.jpg",
            title: "Living Here",
            text: "The best of both worlds: great outdoors and urban centers."
        },
        {
            img: "/images/living-here_0.jpg",
            title: "Our Research Community",
            text: "Identifies, attracts, and recruits exceptional diverse talent."
        },
    ]
    return (
        <div>
            <div className="about_div">

                <Container>
                    <Row>
                        <Col sm={12}  md={3} lg={3}>
                            <div className="text_div">
                                <h4>About Us</h4>
                                <p>we are standing at the intersection of science and technology,and we are foc using on the subject of microfluidics</p>
                                <p></p>
                                <Button variant="outline-primary" href="#member">Learn More</Button>
                            </div>
                        </Col>
                        {
                            cardData.map((item, index) => {
                                return <Col sm={12}  md={3} lg={3} key={item.title}>
                                    <Card className="card_div">
                                        <Card.Img variant="top" src={item.img}/>
                                        <Card.Body>
                                            <Card.Title>{item.title}</Card.Title>
                                            <Card.Text>
                                                {item.text}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            })
                        }

                    </Row>
                </Container>
            </div>
            <div className="careers_div">
                <Container>
                    {
                        careersData.map((item,index)=>{
                            return  <div key={item.title}>
                                <h2 className="careers_title">Careers</h2>
                                <Row className="careers">
                                    <Col md={8} className="img_d">
                                        <div className="img_div">
                                            <div className="p_div">
                                                <div className="career-item-overlay-text">Working Here</div>
                                            </div>
                                            <div>
                                                <img src={item.img} className="img" alt="1"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="careers_p">
                                            <div>  <p>{item.text}</p></div>
                                            <div><Button href="#/research" variant="outline-success">Learn more</Button></div>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        })
                    }
                </Container>
            </div>
        </div>
    )
}
export default OtherData
