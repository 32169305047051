import {Carousel, Container} from "react-bootstrap";
import React from "react";
import "./slide-show.css"

const SlideShow = () => {
    const imageData = [
        {
            img: "/images/1.png",
            key: "First slide"
        },
        {
            img: "/images/2.png",
            key: "Second slide"
        },
        {
            img: "/images/3.png",
            key: "Third slide"
        },
        {
            img: "/images/4.png",
            key: "Third slide"
        },
    ]
    return (
        <div className="banner">
            <Container>
                <Carousel data-bs-theme="dark">
                    {
                        imageData.map((item, index,) => {
                            return <Carousel.Item key={item.key}>
                                <img
                                    className="d-block w-100"
                                    src={item.img}
                                    alt=""
                                />
                                {/*<Carousel.Caption>*/}
                                {/*    <h3>First slide label</h3>*/}
                                {/*    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                {/*</Carousel.Caption>*/}
                            </Carousel.Item>
                        })
                    }

                </Carousel>
            </Container>
        </div>
    )
}
export default SlideShow