import React from "react";
import {Button, Container, Form, FormControl, Nav, Navbar} from "react-bootstrap";
import "./card.css"
const Header = () => {

    const link = [
        {
            text: "Home",
            key: "/"
        },
        {
            text: "Research",
            key: "/research"
        },
        {
            text: "Events",
            key: "/events"
        },

        {
            text: "Members",
            key: "/members"
        },
        {
            text: "Publications",
            key: "/publications"
        },
        {
            text: "Tech Transfer",
            key: "/tech_transfer"
        },
        {
            text: "Contact",
            key: "/contact"
        }]
    return (
        <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src={"/images/logo.png"}
                        width="50"
                        height="50"
                        className=""
                    />{'    '}
                    M²EN Lab
                </Navbar.Brand>                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className="img">
                    <Nav className="me-auto">
                        {
                            link.map((item, index) => {
                                return <Nav.Link href={item.key} key={item.key} active={item.key===window.location.pathname}>{item.text}</Nav.Link>
                            })
                        }

                    </Nav>
                    {/*<Form className="d-flex">*/}
                    {/*    <FormControl*/}
                    {/*        type="search"*/}
                    {/*        placeholder="Search"*/}
                    {/*        className="me-2"*/}
                    {/*        aria-label="Search"*/}
                    {/*    />*/}
                    {/*    <Button variant="outline-success">Search</Button>*/}
                    {/*</Form>*/}
                </Navbar.Collapse>

            </Container>
        </Navbar>
    )
}

export default Header