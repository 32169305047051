import React, {useState} from "react";
import {Col, Container, Image, ListGroup, Row} from "react-bootstrap";
import './publications.css'

const Publications = () => {
    const [listStatus, setListStatus] = useState("")
    const myDate = new Date();
    const tYear = myDate.getFullYear();
    let year = Array.from(new Array(tYear + 1).keys()).slice(2016).reverse()
    let list=[...Array(1)].map(_=>0);
    const listData = ['Microfluidics', 'Imaging', 'Optoelectronics', 'Surface Chemistry', 'Materials Chemistry', 'Archaeology', 'Machine learning','Others']
    return (
        <div className="publication">
            <Container>
                <Row>
                    <Col md={3}>
                        <hr/>
                        <h5>All</h5>
                        <ListGroup variant="flush">
                            <ListGroup.Item action className="border_list" active={listStatus === ""} onClick={() => {
                                setListStatus('')
                            }}>All</ListGroup.Item>
                        </ListGroup>
                        <hr/>
                        <h5>BY CATALOG</h5>
                        <ListGroup variant="flush">
                            {
                                listData.map((item, index) => {
                                    return <ListGroup.Item action active={listStatus === item} className="border_list"
                                                           key={item} onClick={() => {
                                        setListStatus(item)
                                    }
                                    }>{item}</ListGroup.Item>
                                })
                            }
                        </ListGroup>
                        <hr/>
                        <h5>BY YEAR</h5>

                        <ListGroup variant="flush">
                            {

                                year.map((item, index) => {
                                    return <ListGroup.Item action active={listStatus === item.toString()}
                                                           className="border_list"
                                                           key={item} onClick={() => {
                                        setListStatus(item.toString())
                                    }
                                    }>{item}</ListGroup.Item>
                                })
                            }
                        </ListGroup>
                    </Col>
                    <Col md={{span: 8, offset: 1}}>
                        {
                            list.map((item,index)=>{
                                return  <Col md={12} key={index}>
                                    <div className="public">
                                        <Row>
                                            <Col md={{span: 3}}><Image src={"images/clear-dpcr-icon.jpg"}
                                                                       thumbnail={true} className="public_img"/>
                                            </Col>
                                            <Col md={{span: 9}}>
                                                <div className="public_text">
                                                    <div>
                                                        <p>Pan Y., Ma T., Meng Q., Mao Y., Chu K., Men Y., Pan T., Li B., Chu J. Droplet digital PCR enabled by microfluidic impact printing for absolute gene quantification.</p>
                                                    </div>
                                                    <div><p>Talanta, 2020, 211: 120680.</p></div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            })
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Publications