import React, {useEffect, useState} from "react";
import {Button, Col, Container, Form, FormControl, Row} from "react-bootstrap";
import './contact.css'
const BMap = window.BMap;
const Contact = () => {

    const getMap = () => {
        var map = new BMap.Map("allmap"); // 创建Map实例
        const point=new BMap.Point(113.996757,22.600916)
        map.centerAndZoom(point, 18); // 初始化地图,设置中心点坐标和地图级别
        // var p1 = new BMap.Point(113.996757,22.600916);
        // var p2 = new BMap.Point(113.996757,22.600916);
        var marker = new BMap.Marker(point);//创建标注
        map.addOverlay(marker);//方法addOverlay() 向地图中添加覆盖物
        map.enableScrollWheelZoom(true);
        // var driving = new BMap.DrivingRoute(map, { renderOptions: { map: map, autoViewport: true } });
        // driving.search(p1, p2);
    };
    useEffect(()=>{
        getMap()
    },[])
    return <div className="contact_div">
        <Container>
            <h2 className="careers_title">Contact us</h2>
            <Row>
                <Col md={5}>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Your Email" />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control type="password" placeholder="Your Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Label>Message</Form.Label>
                            <FormControl as="textarea" aria-label="Your Message" />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Col>
                <Col md={{span:6,offset:1}}>
                    <h4>Address</h4>
                    <p>1068 Xueyuan Avenue, Shenzhen University Town, Nanshan District, Shenzhen, Guangdong, China
                        518055</p><p>Ph-no: +86 186 1123 0987</p><p>Email: yf.men@siat.ac.cn</p>
                    <div id="allmap" style={{  width: '100%', height: '100%' }}></div>
                </Col>
            </Row>
        </Container>
    </div>
}
export default Contact