import React, {Component} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from "../Home/home";
import Research from "../research/research";
import News from "../news/news";
import Members from "../member/members";
import Publications from "../publications/publications";
import TechTransfer from "../tech-transfer/tech-transfer";
import Contact from "../contact/contact";
import Events from "../events/events";
function RouterLink(){
    return (
        <BrowserRouter>
                <Routes>
                    <Route path='' element={<Home/>}/>
                    <Route path='research' element={<Research/>}/>
                    <Route path='news' element={<News/>}/>
                    <Route path='events' element={<Events/>}/>
                    <Route path='members' element={<Members/>}/>
                    <Route path='publications' element={<Publications/>}/>
                    <Route path='tech_transfer' element={<TechTransfer/>}/>
                    <Route path='contact' element={<Contact/>}/>
                </Routes>
        </BrowserRouter>
    )
}

export default RouterLink

