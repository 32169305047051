import React from "react";
import "./events.css";
import {Col, Container, Row} from "react-bootstrap";

const Events = () => {
    return <div>
        <Container>
            <Row>
                <Col md={12}>
                    <div className="error-404 text-center">
                        <div id="ContentDiv">
                            <div id="unfinished_div"><p id="unfinished_p">我们的故事未完待续......</p></div>
                            <div className="timeline">
                                <div className="timeline_item">
                                    <div className="timeline__item__station"></div>
                                    <div className="time"><p>2018.8</p></div>
                                    <div className="timeline__item__content"><p>门博组及家属在欢乐谷欢乐的玩耍~</p><img
                                        src="images/3.jpg" className=""/></div>
                                </div>
                                <div className="timeline_item">
                                    <div className="timeline__item__station"></div>
                                    <div className="time"><p>2018.7</p></div>
                                    <div className="timeline__item__content"><p>门博组第二次团建~</p><img src="images/2.jpg"
                                                                                                  className=""/></div>
                                </div>
                                <div className="timeline_item">
                                    <div className="timeline__item__station"></div>
                                    <div className="time"><p>2018.1</p></div>
                                    <div className="timeline__item__content"><p>门博组第一次团建~</p><img src="images/1.jpg"
                                                                                                  className=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
}
export default Events