import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Home/components/header"
import "./App.css"
import Footer from "./Home/components/footer";
import RouterLink from "./router";
function App() {
    return (
        <div className="App">
            {/*navbar*/}
            <header>
                <Header/>
            </header>
            <div className='nav'/>
            <RouterLink/>

            <footer>
                <Footer/>
            </footer>
        </div>
);
}

export default App;
