import 'bootstrap/dist/css/bootstrap.min.css';
import SlideShow from "./components/slide-show";
import OtherData from "./components/other-data";

const Home = () => {
  return(
      <div>
          <SlideShow/>
          <OtherData/>
      </div>
  )
}

export default Home