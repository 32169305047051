import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./footer.css"

const Footer = () => {
    return (
        <div className="foot_div">
            <Container>
                <Row>
                    <Col md={{span: 7, offset: 1}}>
                        <h4 className="h4_1">Address:</h4>
                        <p>7 th floor, No. 1068 Xueyuan Avenue,</p>
                        <p>Shenzhen University Town, Xili, Nanshan District, Shenzhen, China</p>
                        <p>Postal Code: 518055</p>
                    </Col>
                    <Col md={4}>
                        <h4 className="h4_1">Contact info:</h4>
                        <p>phone: +86 186 1123 0987</p>
                        <p>Fax: &nbsp;&nbsp;&nbsp;&nbsp; +86 755 86392299</p>
                        <p>Email:   &nbsp;&nbsp;yf.men@siat.ac.cn</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Footer